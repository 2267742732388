import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

// Scroll Buttons

function scrollRight() {
    document.getElementById('outsider').scrollLeft += 800;
}
function scrollLft() {
    document.getElementById('outsider').scrollLeft -= 800;
}


const TeamMini = () => {

    const data = useStaticQuery(graphql`
        query People2 {
            directus {
                Team {
                First_Name
                Last_Name
                Photo {
                    id
                    imageFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE)
                    }
                    }
                }
                }
            }
        }
        `)


    return (
        <section className='py-24 bg-gray-100'>
            <div className="container mx-auto flex flex-row">

                <button onClick={scrollLft} className={`rounded-full flex-none w-12 h-12 bg-blue-500/50 self-center hover:bg-blue-500 hover:text-white cursor-pointer z-10 `}>
                    <ChevronLeftIcon className='h-8 w-8 mx-auto self-center' />
                </button>

                <div id="outsider" className="-ml-4 -mr-4 grow flex flex-row flex-nowrap items-end space-x-2 overflow-auto hide-scrollbar overscroll-x-contain scroll-smooth ">
                    {data.directus.Team.map((person, index) => (
                        <Link to={`/about#${person.First_Name} ${person.Last_Name}`}>

                            <div className='z-0'>
                                <div className={`w-72 bg-gray-200 hover:bg-blue-300 rounded-md`}>
                                    <GatsbyImage image={person.Photo.imageFile.childImageSharp.gatsbyImageData} className={"object-cover object-top grayscale hover:grayscale-0 h-96 " + (index % 2 === 1 ? "transform scale-x-[-1]" : "")} alt={person.First_Name} />
                                </div>
                            </div>
                        </Link>

                    ))}
                </div>

                <button onClick={scrollRight} className="rounded-full flex-none w-12 h-12 bg-blue-500/50 self-center overflow-visible hover:bg-blue-500 hover:text-white cursor-pointer z-10">
                    <ChevronRightIcon className='h-8 w-8 mx-auto self-center' />
                </button>


            </div>
        </section>
    )
}

export default TeamMini